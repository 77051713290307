import { CreateParams, UpdateParams, DataProvider } from 'ra-core';
import _ from 'lodash';
import config from '../configprovider';
import createDefaultDataProvider from './default';
import uploadsPicturesProvider from './uploadsPictures';
import { fetchUtils } from 'react-admin';
import slugify from 'slugify';

const httpClient = fetchUtils.fetchJson;

export const modelDataProvider : DataProvider = createDefaultDataProvider(config('MODEL_API_URL'), 30);

export const createPostDataProvider = (apiUrl : string, ttl = 0) : DataProvider => ({
  ...modelDataProvider,
  getOne : async (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, { user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }}).then(({ json } : any) => {
      return {
        data: {
          ...json, 
          main : json.main ? {id : json.main, src : `${config('UPLOAD_API_URL')}/blog-pictures/${json.main}/binary/medium`} : null,
          thumbnail : json.thumbnail ? {id : json.thumbnail, src : `${config('UPLOAD_API_URL')}/blog-pictures/${json.thumbnail}/binary/medium`} : null
        },
        ...(ttl ? { validUntil : new Date(Date.now() + ttl*1000) } : {})
      }
    })
  },
  create : async (resource, params) => {
    let newParams: CreateParams<any>;
    const MainPhotoToCreate = params.data?.main?.rawFile;
    let newMainPhotoId;
    if(MainPhotoToCreate){
      const newParams = {
        data : {
          __file__ : params.data.main
        }
      }
     const {data} = await uploadsPicturesProvider.create('blog-pictures', newParams);
     newMainPhotoId = data.id;
    }

    const ThumbnailPhotoToCreate = params.data?.thumbnail?.rawFile;
    let newThumbnailPhotoId;
    if(ThumbnailPhotoToCreate){
      const newParams = {
        data : {
          __file__ : params.data.thumbnail
        }
      }
     const {data} = await uploadsPicturesProvider.create('blog-pictures', newParams);
     newThumbnailPhotoId = data.id;
    }

    newParams = {
      ...params,
      data : {
        ...params.data,
        main : newMainPhotoId,
        thumbnail : newThumbnailPhotoId,
        slug: slugify(`${params?.data?.title}`, {
					strict: true, // strip special characters except replacement, defaults to `false`
				}),
      }
    }

    return modelDataProvider.create(resource, newParams)
  },
  update : async (resource, params) => {
    let newParams: UpdateParams<any>;
    const { previousData } = params;

    // Main photo treatment
    const MainPhotoToDelete = previousData?.main?.id !== params.data?.main?.id;
    const MainPhotoToCreate = params.data?.main?.rawFile;
    let newMainPhotoId;
    if(MainPhotoToDelete){
      await uploadsPicturesProvider.delete('blog-pictures', {id : previousData.main.id});
      newMainPhotoId = null;
    }
    if(MainPhotoToCreate){
      const newParams = {
        data : {
          __file__ : params.data.main
        }
      }
     const {data} = await uploadsPicturesProvider.create('blog-pictures', newParams);
     newMainPhotoId = data.id;
    }
    if(!MainPhotoToDelete && !MainPhotoToCreate){
      newMainPhotoId = previousData?.main?.id || null;
    }
    
    // Thumbnail photo treatment
    const ThumbnailPhotoToDelete = previousData?.thumbnail?.id !== params.data?.thumbnail?.id;
    const ThumbnailPhotoToCreate = params.data?.thumbnail?.rawFile;
    let newThumbnailPhotoId;
    if(ThumbnailPhotoToDelete){
      await uploadsPicturesProvider.delete('blog-pictures', {id : previousData.thumbnail.id});
      newThumbnailPhotoId = null;
    }
    if(ThumbnailPhotoToCreate){
      const newParams = {
        data : {
          __file__ : params.data.thumbnail
        }
      }
     const {data} = await uploadsPicturesProvider.create('blog-pictures', newParams);
     newThumbnailPhotoId = data.id;
    }
    if(!ThumbnailPhotoToDelete && !ThumbnailPhotoToCreate){
      newThumbnailPhotoId = previousData?.thumbnail?.id || null;
    }

    newParams = {
      ...params,
      data : {
        ...params.data,
        main : newMainPhotoId,
        thumbnail : newThumbnailPhotoId,
        slug: slugify(`${params?.data?.title}`, {
					strict: true, // strip special characters except replacement, defaults to `false`
				}),
      }
    }
    return modelDataProvider.update(resource, newParams)
  }, 
})