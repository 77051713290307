import { TranslationMessages } from 'ra-core';
import englishMessages from 'ra-language-english';

const customMessages : TranslationMessages = {
  ...englishMessages,
  resources : {
  }

};

export default customMessages;
